a {
    color: black;
    text-decoration: underline;
}

img {
    pointer-events: none;
}

.logo-container {
    border-right: 1px solid;border-bottom: 1px solid;
    background: rgb(234, 234, 226);
}
.logo-container img{
    margin-top: 0;
    padding: 0px;
    max-width: 250px;
}

#select-gender {
    margin: 0px 10px;
    height: 30px;
}

#select-gender select{
    font-size: 13px;
}

.select:not(.is-multiple):not(.is-loading)::after{
    margin-top: -6px;
    border-color:black;
}

#menu{
    position: fixed;
    width: 100%;
    background: white;
    z-index: 3;
}
/* #collections-body {
    padding-top: 230px;
} */
.collections-body {
    padding-top: 199px;
}
.collections-body.single{
    background-color: #c4c9cc;
}
.collections-body h4{
    white-space: normal;
}
.wishlist-body {
    padding-top: 186px !important;
}
.cart-container {
    border-bottom: 1px solid;
    padding-top: .75rem;
    background: rgb(234, 234, 226);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#add-update-selection {
    display: block;
}
/* #cart-text {
    display: block;
}
#cart-image {
    position: relative;
    top: 0px;
    right: 0px;
} */
#seasons-container {
    border-top: none;
    background: rgb(234, 234, 226);
    border-bottom: 1px solid;
}
#item-seasons-container {
    display: none;
}
.collection-list {
    flex-wrap: wrap;
}
.collection-container {
    flex-wrap: wrap;
}
.collection-container img{
    max-width: 100%;
}
.item {
    border-bottom: 1px solid;
    border-right: 1px solid;
}
.item > a >.columns {
    flex-direction: column;    
}
.item > a  {
    color: black;    
}
.item h6 {
    font-size: 14px;
}
.item:nth-child(4){
    border-right: none;
}

.item-left-container {
    display: block;
    position: relative;
    margin: 0;
    padding-left: 0;
    padding-right: 23px;
}

#vertical-scroll-images {
    position: absolute;
    width: 102%;
    overflow-y: auto;
    overflow-x: hidden;
    /* margin-top: 12px; */
    margin-top: 30px;
}

#vertical-scroll-images > .columns {
    flex-direction: column;
    margin: 0;
}

#vertical-scroll-images .column {
    border-bottom:1px solid;
    padding: 0px;
}

#vertical-scroll-images .column:last-child {
    border-bottom:none;
}

#vertical-scroll-images img {
    /* padding-top: 100px; 
    padding-bottom: 100px; */
}
.item-description-container {
    /* padding-right: 35px; */
    /* padding-right: 12px; */
    border-left:1px solid; 
    position: relative;
    /* margin-left: -13px; */
    z-index: 2;
    padding-top: 55px;
}
.fixed-heigth-container {
    overflow-x: hidden;
    overflow-y: auto;
    margin: -0.75rem;
}
.lh-2rem {
    line-height: 2rem;
}
.bb-1 {
    border-bottom: 1px solid;
}
#add-wish-button-container {
    position: absolute;
    width: 102%;
    bottom: -12px;
}
#add-wish-button-container .columns {
    align-items: center;
    margin:0px;
}
#add-wish-button-container > .column:first-child {
    display: none;
}
#add-to-selection {
    background: black;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
#add-to-selection h3{
    color: white;
}
#plus-button-container {
    padding: 0px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
}
#item-size-list, #item-qty-container {
    display: block;
}

::-webkit-scrollbar {
    width: 12px;  /* Remove scrollbar space */
    background: white;  /* Optional: just make scrollbar invisible */
}
#item-description p{
    margin-bottom: 20px;
}
#item-navigation {
    position: relative;
    width: 100%;
    z-index: 1;
    background: white;
    padding-bottom: 0px;
}
#item-body {
    /* padding-top: 234px; */
    /* padding-top: 220px; */
    padding-top: 156px;
}

.list-item {
    border-bottom: 1px solid;border-right: 1px solid;
    font-size: 10px;
    display: flex;
  align-items: center;
  justify-content: center;
}

.width-54 {
    width: 30px;
    padding: 0;
    overflow: auto;
}
.wishlist-table {
    width: 1369px;
    margin-top: 0;
}
.wishlist-table tr{
    border-bottom: 1px solid;
}
.wishlist-table tr th{
    border-top: 1px solid;
}
.wishlist-table tr th, .wishlist-table tr td{
    text-align: center;
    border-right: 1px solid;
    padding: 11px;
    display: table-cell;
    vertical-align: middle;
}

.flex-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.flex-child > h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .item-container {
    height: 340px;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #c4c9cc;
}
.block-scroll {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#images-carousel {
    display: none;
}

@media  (max-width: 767px) {
    .wishlist-table {
        margin-top: 98px;
    }
    #add-update-selection {
        display: none;
    }
    #images-carousel {
        display: block;
        margin-bottom: 30px;
    }
    .fixed-heigth-container {
        overflow: hidden;
    }
    #item-body {
        /* padding-top: 258px; */
        padding-top: 234px;
    }
    #item-navigation {
        position: fixed;
        padding-bottom: 12px;
    }
    #add-wish-button-container {
        position: fixed;
        bottom: 0px;
        padding: 10px;
        width: 100%;
        background:rgb(97,107,111);
    }
    #add-wish-button-container > .column:first-child {
        color: white;
        display: block;
    }
    #item-description p{
        margin-bottom: 60px;
    }
    #add-wish-button-container > .column {
        padding: 0px;
    }
    #add-wish-button-container > .column > .columns {
        display: flex;
    }
    #item-size-list, #item-qty-container {
        display: none;
    }
    #item-price-container {
        padding: 0px;
    }
    #item-price-container > .columns {
        display: flex;
        margin: 0px;
    }
    .item-description-container {
        /* padding-right: 0px; */
        border-left: none;
        padding-top: 0;
    }
    .item-description-container .is-size-3 {
        font-size: 1.5rem!important;
    }
    .lh-2rem {
        line-height: 1.5rem;
    }
    .item-left-container {
        display: none;
    }
    .item {
        border-bottom: 1px solid;
        border-right: 1px solid;
        padding: 0px 0px 0px;
    }
    .collections-body {
        padding-top: 220px;
        display: block;
        /* overflow-x: auto;
        white-space: nowrap; */
    }
    /* .collections-body p {
        text-overflow: ellipsis;
        overflow: hidden;
    } */
    .collection-list {
        display: flex;
    }
    .item:nth-child(2){
        border-right: none;
    }
    .columns {
        margin: 0px;
    }
    .logo-container {
        border-right: none;
        border-bottom: none;
    }
    .logo-container img {
        max-width: 220px;
        margin-top: 20px;
    }
   
    .cart-container {
        background: transparent;
        border-bottom: none;
        padding-top: 0px;
        position: absolute;
        right: 0;
        top: -30px;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .cart-container img {
        display: none;
    }
    #cart-text {
        display: none;
    }
    #cart-image {
        position: absolute;
        top: 10px;
        right: 0px;
    }
    #seasons-container {
        border-top: 1px solid;
        display: flex;
    }
    #item-seasons-container {
        display: flex;
        border-top: 1px solid;
    }
    .collection-container img{
        max-width: 80%;
    }
    .collection-container div:first-child{
        padding: 0px;
    }
    #menu > .columns {
        margin-bottom: 0px;
    }
    #seasons-container > .column {
        width: 50%;
    }
    .single-collection-item-name-container {
        padding: 0px;
    }     
    .single-collection-item-name-container img {
        width: 25px;
    }
    .collection-list.columns:last-child, .collection-list .columns:last-child{
        margin-bottom: 0px;
    }
    .item-container {
        height: 300px;
        display: flex;
        background-position: center;
        background-size: contain;
    }
}

@media screen and (min-width: 1024px){
    #menu {
        max-width: 960px;
    }
}

@media screen and (min-width: 1408px){
    #menu {
        max-width: 1344px;
    }
}
